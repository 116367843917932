import styled from "styled-components";
import { Drawer } from "@mui/material";
import { IconButton } from "@flash-tecnologia/hros-web-ui-v2";

export const StyledDrawer = styled(Drawer)``;

export const Container = styled.div`
  width: 680px;
`;

export const TitleContainer = styled.div`
  padding: 32px 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ebe6e9;
  position: sticky;
  top: 0;
  z-index: 99;
  background: #fff;
`;

export const StyledIconButton = styled(IconButton)``;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;
