import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { Avatar } from "./Avatar";
import {
  BasicInfosContainer,
  ButtonsContainer,
  Container,
  DividerContainer,
  EmployeeFieldsContainer,
  GradientBanner,
  LeadershipContainer,
  NameBanner,
  StyledButton,
  StyledLinkButton,
  StyledTooltip,
  StyledTypography,
} from "./styled";
import { Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import dispatchToast from "../../../../utils/dispatchToast";
import { usePermissions } from "@flash-tecnologia/hros-web-utility";
import { segment } from "src/utils/segment";

export interface IPersonDetails {
  name: string;
  role?: string;
  src?: string;
  department?: string;
  corporateEmail?: string;
  employeeId?: string;
  isCEO?: boolean;
  leader?: { _id: string; name: string };
  getPersonDetails: () => void;
  centerPerson: () => void;
}

interface IEmployeeFields {
  field: string;
  value: string;
}

export const PersonDetails = ({
  name,
  role = "",
  src = "",
  department = "",
  corporateEmail = "",
  employeeId = "",
  isCEO = false,
  leader = { _id: "", name: "" },
  getPersonDetails,
  centerPerson,
}: IPersonDetails) => {
  const permissions = usePermissions();

  const hasBackbonePermission = permissions.companies.some(
    (company) =>
      (company.permissions.includes("core_view_employees_profile") &&
        company.permissions.includes("core_manage_employees_list")) ||
      company.permissions.includes("*")
  );

  const employeeFields: IEmployeeFields[] = [
    { field: "E-mail corporativo", value: corporateEmail },
    { field: "Departamento", value: department },
  ];

  const navigate = useNavigate();

  return (
    <Container>
      <GradientBanner src={src}>
        <Avatar name={name} src={src} />
      </GradientBanner>
      <NameBanner>
        <StyledTypography
          variant="headline7"
          weight={700}
          customColor={30}
          style={{ marginBottom: "4px", textTransform: "capitalize" }}
        >
          {name}
        </StyledTypography>
        <StyledTypography variant="body2" weight={600} customColor={40}>
          {role}
        </StyledTypography>
      </NameBanner>

      <ButtonsContainer>
        <StyledButton
          variant="secondary"
          size="medium"
          onClick={() => {
            centerPerson();
            segment({
              track: `people_strategic_hr_orgchart_checkonorgchart_option_clicked`,
            });
          }}
        >
          Ver no Organograma{" "}
          <Icons size={24} name="IconHierarchy" color="#F20D7A" />
        </StyledButton>
        {hasBackbonePermission && (
          <StyledButton
            variant="secondary"
            size="medium"
            onClick={() => {
              segment({
                track: `people_strategic_hr_orgchart_checkfullprofile_option_clicked`,
              });
              navigate(`/employees/${employeeId}/manage`);
            }}
          >
            Ver perfil completo{" "}
            <Icons size={24} name="IconExternalLink" color="#F20D7A" />
          </StyledButton>
        )}
      </ButtonsContainer>
      <BasicInfosContainer>
        <StyledTypography variant="headline8" weight={700} customColor={30}>
          Informações básicas
        </StyledTypography>
        <EmployeeFieldsContainer>
          {employeeFields?.map((fields, index) => {
            return (
              <div key={index}>
                <StyledTypography
                  variant="caption"
                  weight={600}
                  customColor={50}
                >
                  {fields?.field ? fields?.field : "-"}
                </StyledTypography>
                <StyledTypography
                  variant="body3"
                  weight={600}
                  customColor={30}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "4px",
                    alignItems: "center",
                  }}
                >
                  {fields?.value ? fields?.value : "-"}
                  {fields?.value && fields?.field == "E-mail corporativo" && (
                    <StyledTooltip
                      title="Copiar e-mail corporativo"
                      placement="right"
                    >
                      <button style={{ cursor: "pointer" }}>
                        <Icons
                          name="IconCopy"
                          size={16}
                          color="#6B5B66"
                          onClick={() => {
                            navigator.clipboard.writeText(fields?.value);
                            dispatchToast({
                              type: "success",
                              content:
                                "E-mail copiado para a área de transferência.",
                            });
                          }}
                        />
                      </button>
                    </StyledTooltip>
                  )}
                </StyledTypography>
              </div>
            );
          })}
        </EmployeeFieldsContainer>
      </BasicInfosContainer>
      {!isCEO && (
        <>
          <DividerContainer>
            <Divider orientation="horizontal" />
          </DividerContainer>
          <LeadershipContainer>
            <StyledTypography variant="headline8" weight={700} customColor={30}>
              Liderança
            </StyledTypography>
            <div>
              <StyledTypography variant="caption" weight={600} customColor={50}>
                Líder imediato
              </StyledTypography>
              <StyledLinkButton
                variant="secondary"
                onClick={() => {
                  getPersonDetails();
                }}
              >
                <StyledTypography
                  variant="body3"
                  weight={700}
                  customColor={40}
                  style={{ textTransform: "capitalize" }}
                >
                  {leader?.name?.toLowerCase()}
                </StyledTypography>
              </StyledLinkButton>
            </div>
          </LeadershipContainer>
        </>
      )}
    </Container>
  );
};
