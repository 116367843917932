import { Icons, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";
import {
  AddUnderIcon,
  Avatar,
  AvatarContainer,
  AvatarImage,
  AvatarName,
  AvatarTopDiv,
  CardContainer,
  CardTopDiv,
  CompanyLogo,
  Elipsis,
  InfosContainer,
  PersonName,
  RoleName,
  ThreeDotsContainer,
} from "./styled";
import { formatRegistrationNumber } from "src/utils/formatRegistrationNumber";

type ICard = {
  image: string;
  name: string;
  role: string;
  nameInitials: string;
  onClick: (e) => void;
  isLoading?: boolean;
  interactive: boolean;
  registrationNumber: string;
  hasPermission?: boolean;
};

export const Card = ({
  image,
  name,
  role,
  nameInitials,
  onClick,
  isLoading,
  interactive,
  registrationNumber,
  hasPermission,
}: ICard) => {
  return (
    <CardContainer>
      <CardTopDiv>
        {!registrationNumber ? (
          <AvatarTopDiv>
            <AvatarContainer>
              <Avatar hasImage={!!image}>
                {image ? (
                  <AvatarImage src={image} />
                ) : (
                  <AvatarName weight={700} variant="body1">
                    {nameInitials}
                  </AvatarName>
                )}
              </Avatar>
            </AvatarContainer>
          </AvatarTopDiv>
        ) : (
          <div style={{ height: "84px" }} />
        )}

        <InfosContainer
          style={{
            padding:
              registrationNumber && !isLoading ? "38px" : "0px 24px 24px",
            gap: registrationNumber && !isLoading ? "8px" : "4px",
          }}
        >
          {interactive && !registrationNumber && !isLoading && (
            <ThreeDotsContainer permission={hasPermission}>
              <Icons
                className="card-button-foreign-object"
                name="IconDotsVertical"
                width={"16px"}
                color="#53464F"
                onClick={(e) => {
                  onClick(e);
                }}
              />
            </ThreeDotsContainer>
          )}
          {isLoading ? (
            <Skeleton style={{ marginTop: "32px", width: "100%" }} />
          ) : registrationNumber && image ? (
            <CompanyLogo alt="company-logo" src={image} />
          ) : (
            <PersonName variant="body3">{name}</PersonName>
          )}

          {isLoading ? (
            <Skeleton style={{ marginTop: "8px", width: "100%" }} />
          ) : (
            <Elipsis style={{ minHeight: "20px" }}>
              <RoleName variant="body4">
                {registrationNumber
                  ? `CNPJ ${formatRegistrationNumber(registrationNumber)} `
                  : role}
              </RoleName>
            </Elipsis>
          )}
        </InfosContainer>
      </CardTopDiv>
      {!isLoading && (
        <AddUnderIcon permission={hasPermission} className="card-button-add">
          <Icons
            name="Add"
            width={"12px"}
            color="#53464F"
            className="card-button-add"
          />
        </AddUnderIcon>
      )}
    </CardContainer>
  );
};
