import { Divider, PageContainer } from "@flash-tecnologia/hros-web-ui-v2";
import { Orgchart } from "@components/Orgchart";
import { useRef, useState } from "react";
import { OrgChart } from "src/lib";
import { trpc } from "@api/client";
import {
  usePermissions,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility";
import { Title } from "./components/Title";
import { Toolbar } from "./components/Toolbar";
import styled from "styled-components";
import { TOrgchartQueryProps } from "./components/Toolbar/components/ModalExport";
import dispatchToast from "src/utils/dispatchToast";
import { segment } from "src/utils/segment";
import { GetOrgChartByLeaderResponseV2 } from "server/src/services";

const Container = styled.div`
  max-height: calc(100vh - 74px);
  overflow: hidden;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 40px;
`;

export const PageMain = () => {
  const { selectedCompany } = useSelectedCompany();
  const currentCompanyId = selectedCompany?.id;

  const permissions = usePermissions();
  const hasPermission = permissions?.companies?.some(
    (company) =>
      company?.permissions?.includes("people_orgchart_update") ||
      company?.permissions?.includes("*") ||
      permissions?.isAdmin
  );

  segment({ track: `people_strategic_hr_orgchart_view` });

  const [orgchartQueryProps, setOrgchartQueryProps] =
    useState<TOrgchartQueryProps>();

  const orgchartRef = useRef<OrgChart<unknown>>(new OrgChart());

  const [
    {
      data: getOrgChartByLeader,
      refetch: getOrgChartByLeaderRefetch,
      isLoading: getOrgChartByLeaderIsLoading,
    },
    {
      data: getRolesAndDepartmentsResponse,
      refetch: getRolesAndDepartmentsResponseRefetch,
      isLoading: getRolesAndDepartmentsResponseIsLoading,
    },
  ] = trpc.useQueries((t) => [
    t.getOrgchartByLeaderV2(
      {
        leaderId: orgchartQueryProps?.leaderId ?? "",
        levelsToExport: orgchartQueryProps?.levelsToExport ?? 0,
        companyId: currentCompanyId,
      },
      {
        retry: false,
        refetchOnWindowFocus: false,
        onError: () => {
          dispatchToast({
            type: "error",
            content:
              "Sentimos muito, ocorreu um erro ao carregar o Organograma. Por favor, recarregue a página ou tente novamente mais tarde.",
          });
        },
      }
    ),
    t.getRolesAndDepartments(
      { companyId: currentCompanyId },
      {
        retry: false,
        refetchOnWindowFocus: false,
      }
    ),
  ]);

  const refetch = () => {
    getOrgChartByLeaderRefetch();
    getRolesAndDepartmentsResponseRefetch();
  };
  const isLoading =
    getOrgChartByLeaderIsLoading || getRolesAndDepartmentsResponseIsLoading;

  const getPeopleCount = (data: GetOrgChartByLeaderResponseV2 | undefined) => {
    const employees = data?.orgchart?.filter(
      (card) => card?.cardType != "company"
    );

    return employees?.length;
  };

  return (
    <Container>
      <PageContainer>
        <Wrapper>
          <Title count={getPeopleCount(getOrgChartByLeader)} />
          <Toolbar
            orgchartRef={orgchartRef}
            getOrgChartByLeader={getOrgChartByLeader}
            getRolesAndDepartments={getRolesAndDepartmentsResponse}
            setOrgchartQueryProps={setOrgchartQueryProps}
            isLoading={isLoading}
            refetch={refetch}
          />
        </Wrapper>
      </PageContainer>
      <Divider orientation="horizontal" />
      <Orgchart
        ref={orgchartRef}
        getOrgChartByLeader={getOrgChartByLeader}
        isLoading={isLoading}
        refetch={refetch}
        interactive={!isLoading}
        compactOrgchart={false}
        hasPermission={hasPermission}
      />
    </Container>
  );
};
