import { Outlet, Navigate } from "react-router-dom";

import {
  getFromLS,
  useContractModules,
} from "@flash-tecnologia/hros-web-utility";

import { useAuth } from "@flash-hros/auth-helper";

export const OpenRoutesGuard = ({ children }: { children?: JSX.Element }) => {
  const isLoggedIn = getFromLS("hrosAccessToken");

  const contractStatus = useAuth.getState().contractStatus || null;

  const peopleContractModules = useContractModules();

  const hasModule = peopleContractModules?.modules?.includes("orgchart");

  if (!isLoggedIn) {
    return <Navigate to={"/authentication/login"} />;
  }

  if (!hasModule) {
    return <Navigate to={"/home"} />;
  }

  if (contractStatus?.url) {
    return <Navigate to={contractStatus?.url} state={{ from: location }} />;
  }

  return children ? children : <Outlet />;
};
