import { useEffect, useRef, useState } from "react";
import { trpc } from "@api/client";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { Radio, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { OrgChart } from "src/lib";
import jsPDF from "jspdf";
import { Divider } from "@mui/material";
import { ModalFooter } from "./components/ModalFooter/ModalFooter";
import { ModalHeader } from "./components/ModalHeader/ModalHeader";
import { TitleBox } from "./components/TitleBox";
import { segment } from "src/utils/segment";
import dispatchToast from "src/utils/dispatchToast";
import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";

import {
  ConfigContainer,
  OptionsDiv,
  StyledGridContainer,
  StyledGridItem,
  StyledGridItem2,
  StyledModal,
  StyledSelectField,
  VisualizeContainer,
  OrgchartContainer,
} from "./styled";
import { Orgchart } from "src/components/Orgchart";
import { GetOrgChartByLeaderResponseV2 } from "server/src/services";

export type TOrgchartQueryProps =
  | { leaderId: string; levelsToExport: number }
  | undefined;

type IModal = {
  isOpen: boolean;
  getOrgChartByLeader?: GetOrgChartByLeaderResponseV2;
  closeModal: () => void;
  onQueryPropsChange: (queryProps: TOrgchartQueryProps) => void;
  isLoading: boolean;
  refetch: () => void;
};

interface IOptions {
  value: string | number;
  label: string;
}

const exportOrgChartOptions = [
  "Organograma completo",
  "Personalizar exportação",
];

const exportTypeOptions: IOptions[] = [
  { value: "PDF", label: "PDF" },
  { value: "JPEG", label: "JPEG" },
  { value: "PNG", label: "PNG" },
];

export const ModalExport = ({
  isOpen,
  getOrgChartByLeader,
  closeModal,
  onQueryPropsChange,
  isLoading,
  refetch,
}: IModal) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const { selectedCompany } = useSelectedCompany();
  const currentCompanyId = selectedCompany?.id;
  const orgchartRef = useRef<OrgChart<unknown> | null>(null);

  const [selectedOption, setSelectedOption] = useState<string>(
    "Organograma completo"
  );
  const [selectedLeader, setSelectedLeader] = useState<{
    value: string | null;
  }>({ value: "" });
  const [selectedFormat, setSelectedFormat] = useState<string>("");
  const [selectedLevel, setSelectedLevel] = useState<number>(0);
  const [levelValue, setLevelValue] = useState<IOptions>({
    value: 0,
    label: "Todos os níveis",
  });

  const exportLevelOptions = [
    { value: 0, label: "Todos os níveis" },
    ...Array.from({ length: getOrgChartByLeader?.levels ?? 0 }, (_, i) => {
      const level = i + 1;
      return {
        value: `${level}`,
        label: `${level} ${level > 1 ? "níveis" : "nível"}`,
      };
    }),
  ];

  const [orgchartQueryProps, setOrgchartQueryProps] =
    useState<TOrgchartQueryProps>();

  const [{ data: getLeadersResponse }] = trpc.useQueries((t) => [
    t.getLeaders(
      { companyId: currentCompanyId },
      {
        retry: false,
        refetchOnWindowFocus: false,
      }
    ),
  ]);

  const handleLeaderSelectionChange = (value) => {
    setSelectedLeader(value);
    setOrgchartQueryProps({
      leaderId: value?.value,
      levelsToExport: selectedLevel == null ? 0 : selectedLevel,
    });
  };

  const handleLevelSelectionChange = (value) => {
    setLevelValue(value);
    const newSelectedLevel = Number(value?.value);

    orgchartRef?.current?.collapseAll();

    const attrs = orgchartRef?.current?.getChartState();
    const allNodes = attrs?.allNodes;
    const shallowNodes: string[] = [];

    allNodes?.forEach((node) => {
      if (node.depth <= newSelectedLevel) shallowNodes.push(node.id ?? "");
    });

    shallowNodes.forEach((node) => orgchartRef?.current?.setExpanded(node));

    orgchartRef?.current?.render();
  };

  const handleExportSuccess = () => {
    dispatchToast({
      type: "success",
      content: "Organograma exportado com sucesso",
    });
    closeModal();
  };

  const handleExport = (type: string) => {
    if (!selectedFormat) {
      dispatchToast({
        type: "warning",
        content: "Selecione um formato de imagem",
      });
    } else {
      const { width, height } = document
        .getElementById("orgchart-container")
        ?.getBoundingClientRect() || { width: 428, height: 516 };

      setWidth(width);
      setHeight(height);

      orgchartRef?.current?.render(width, height);
      orgchartRef?.current?.fit();

      switch (type) {
        case "JPEG":
          orgchartRef?.current?.exportImg({
            width,
            height,
            full: true,
            save: false,
            scale: 10,
            onLoad: (base64) => {
              var img = new Image();
              img.src = base64;
              img.onload = function () {
                var canvas = document.createElement("canvas");
                var ctx = canvas.getContext("2d") as any;

                canvas.width = img.width;
                canvas.height = img.height;

                ctx.drawImage(img, 0, 0);

                var jpegBase64 = canvas.toDataURL("image/jpeg");

                var link = document.createElement("a");
                link.href = jpegBase64;
                link.download = "org-chart.jpeg";

                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);

                handleExportSuccess();
              };
            },
          });
          break;

        case "PNG":
          orgchartRef?.current?.exportImg({
            width,
            height,
            full: true,
            onLoad: () => handleExportSuccess(),
          });

          break;

        case "PDF":
          orgchartRef?.current?.exportImg({
            width,
            height,
            save: false,
            full: true,
            scale: 10,
            onLoad: (base64) => {
              var pdf = new jsPDF();
              var img = new Image();
              img.src = base64;
              img.onload = function () {
                pdf.addImage(
                  img,
                  "JPEG",
                  5,
                  5,
                  595 / 3,
                  ((img.height / img.width) * 595) / 3
                );
                pdf.save("org-chart.pdf");
              };

              handleExportSuccess();
            },
          });
          break;
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.getElementById("chart-id")?.remove();
    }

    orgchartRef.current = new OrgChart(undefined, 536);
  }, [isOpen]);

  useEffect(() => {
    onQueryPropsChange && onQueryPropsChange(orgchartQueryProps);
  }, [orgchartQueryProps]);

  return (
    <StyledModal
      open={isOpen}
      onClose={() => closeModal()}
      header={
        <ModalHeader
          closeModal={closeModal}
          setSelectedLeader={setSelectedLeader}
          setSelectedLevel={setSelectedLevel}
          setLevelValue={setLevelValue}
          setSelectedOption={setSelectedOption}
        />
      }
      footer={
        <ModalFooter
          handleExport={handleExport}
          selectedFormat={selectedFormat}
          closeModal={closeModal}
          setSelectedLeader={setSelectedLeader}
          setSelectedLevel={setSelectedLevel}
          setLevelValue={setLevelValue}
          setSelectedOption={setSelectedOption}
          isLoading={false}
        />
      }
    >
      <StyledGridContainer container>
        <StyledGridItem item>
          <ConfigContainer>
            <TitleBox
              title="Configurar exportação"
              subtitle="Escolha como gostaria de exportar seu organograma."
            />
            {exportOrgChartOptions.map((option, index) => {
              return (
                <OptionsDiv key={index}>
                  <Radio
                    checked={selectedOption == option}
                    onChange={async () => {
                      setSelectedOption(option);
                      if (option == "Organograma completo") {
                        setSelectedLeader({ value: null });
                        setSelectedLevel(0);
                        setLevelValue({ value: 0, label: "Todos os níveis" });

                        setOrgchartQueryProps({
                          leaderId: "",
                          levelsToExport: 0,
                        });

                        segment({
                          track: `people_strategic_hr_orgchart_exportorgchart_button_exportorgchart_modal_fullorgchart_option_clicked`,
                        });
                      } else {
                        segment({
                          track: `people_strategic_hr_orgchart_exportorgchart_button_exportorgchart_modal_customizeorgchart_option_clicked`,
                        });
                      }
                    }}
                  />
                  <Typography variant="body3" weight={600}>
                    {option}
                  </Typography>
                </OptionsDiv>
              );
            })}
            <Divider sx={{ borderColor: "#EBE6E9", width: "100%" }} />
            <StyledSelectField
              label="Formato do arquivo"
              options={exportTypeOptions}
              onClick={() => {
                segment({
                  track: `people_strategic_hr_orgchart_exportorgchart_button_exportorgchart_modal_fileformat_dropdown_clicked`,
                });
              }}
              value={selectedFormat}
              onSelectChange={(e, value) => {
                setSelectedFormat(value.value);
                switch (value?.value) {
                  case "PDF":
                    segment({
                      track: `people_strategic_hr_orgchart_exportorgchart_button_exportorgchart_modal_fileformat_dropdown_pdf_option_clicked`,
                    });
                    break;
                  case "JPEG":
                    segment({
                      track: `people_strategic_hr_orgchart_exportorgchart_button_exportorgchart_modal_fileformat_dropdown_jpeg_option_clicked`,
                    });
                    break;
                  case "PNG":
                    segment({
                      track: `people_strategic_hr_orgchart_exportorgchart_button_exportorgchart_modal_fileformat_dropdown_png_option_clicked`,
                    });
                    break;
                }
              }}
            />
            {selectedOption == "Personalizar exportação" && (
              <>
                <Divider sx={{ borderColor: "#EBE6E9", width: "100%" }} />
                <StyledSelectField
                  label="Selecionar líder"
                  helperText="Ao selecionar um líder, a exportação do organograma começará por ele"
                  value={selectedLeader}
                  options={getLeadersResponse || []}
                  searchable={true}
                  onSelectChange={(e, value) => {
                    handleLeaderSelectionChange(value);
                  }}
                  onFocus={() => {
                    segment({
                      track: `people_strategic_hr_orgchart_exportorgchart_button_exportorgchart_modal_chooseleader_dropdown_clicked`,
                    });
                  }}
                  noOptionsText={"Busca não encontrada"}
                />
                <StyledSelectField
                  label="Selecionar níveis de hierarquia"
                  options={exportLevelOptions}
                  searchable={false}
                  helperText="Escolha quantos níveis abaixo da hierarquia você deseja exportar."
                  value={levelValue}
                  onSelectChange={(e, value) => {
                    handleLevelSelectionChange(value);
                  }}
                  onFocus={() => {
                    segment({
                      track: `people_strategic_hr_orgchart_exportorgchart_button_exportorgchart_modal_choosehierarchylevels_dropdown_clicked`,
                    });
                  }}
                />
              </>
            )}
          </ConfigContainer>
        </StyledGridItem>
        <StyledGridItem2 item>
          <VisualizeContainer>
            <TitleBox
              title="Pré-visualização"
              subtitle="Acompanhe a pré-visualização para certificar-se que a exportação está correta."
            />
            {/* <ResizableBox
              width={width}
              height={height}
              onResize={(_, { size }) => {
                setWidth(size.width);
                setHeight(size.height);
              }}
              onResizeStop={() => {
                orgchartRef?.current?.render(width, height);
              }}
              minConstraints={[300, 50]}
              maxConstraints={[774, 360]}
            > */}
            <OrgchartContainer id="orgchart-container">
              <Orgchart
                ref={orgchartRef}
                getOrgChartByLeader={getOrgChartByLeader}
                isLoading={isLoading}
                interactive={false}
                refetch={refetch}
                compactOrgchart={true}
              />
            </OrgchartContainer>
            {/* </ResizableBox> */}
          </VisualizeContainer>
        </StyledGridItem2>
      </StyledGridContainer>
    </StyledModal>
  );
};
