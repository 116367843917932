import {
  Icons,
  LinkButton,
  SearchField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import styled from "styled-components";

export const StyledAccordion = styled(Accordion)`
  &.MuiPaper-root {
    border: 1px solid #ebe6e9;
    border-radius: 8px;
    box-shadow: none;

    &::before {
      background-color: transparent;
    }
  }
  .MuiButtonBase-root {
    padding: 24px;
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  padding: 24px;
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  &.MuiAccordionDetails-root {
    padding: 0px;
  }
`;

export const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark3};
`;

export const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
`;

export const StyledContainerIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 10px;

  width: 48px;
  height: 48px;

  background: ${({ theme }) => theme.colors.neutral[95]};
  border-radius: 40px;
`;

export const StyledDiv = styled.div`
  padding: 16px 24px 24px 24px;
`;

export const SearchInputContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: ${(props) => props.theme.spacings.xs3};
  width: 100%;
  &:before,
  &:after {
    content: none;
  }
`;

export const SearchInputBorder = styled.div`
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: ${(props) => props.theme.spacings.xs3};
  height: 60px;
  width: 100%;
  border: ${(props) => props.theme.borders.width.xs2} solid
    ${(props) => props.theme.colors.neutral[70]};
  border-radius: 150px;
  &:before,
  &:after {
    content: none;
  }
`;

export const SearchIcon = styled(Icons)`
  position: relative;
  left: 20px;
  fill: transparent;
  z-index: 1;
  circle,
  line {
    stroke: ${(props) => props.theme.colors.neutral[70]};
  }
`;

export const SelectAllContainer = styled.div<{ showSelectAllButton?: boolean }>`
  display: ${({ showSelectAllButton }) =>
    showSelectAllButton ? "flex" : "none"};
  align-items: center;
  background-color: transparent;
  gap: ${(props) => props.theme.spacings.xs};
  padding: 0px ${(props) => props.theme.spacings.xs3};
  width: 100%;
  border-top: ${(props) => props.theme.borders.width.xs2} solid
    ${(props) => props.theme.colors.neutral[90]};
  border-bottom: ${(props) => props.theme.borders.width.xs2} solid
    ${(props) => props.theme.colors.neutral[90]};
`;

export const SelectAllItem = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  background-color: transparent;
  gap: ${(props) => props.theme.spacings.xs3};
`;

export const StyledSearchField = styled(SearchField)`
  margin-bottom: 8px;
  .MuiAutocomplete-endAdornment {
    display: none;
  }
`;

export const ClearButtonContainer = styled.div`
  padding: ${(props) =>
    `${props.theme.spacings.xs} ${props.theme.spacings.xs2}`};
  border-top: 1px solid ${(props) => props.theme.colors.neutral["90"]};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MenuItem = styled.button<{ checked?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  gap: ${(props) => props.theme.spacings.xs3};
  background-color: ${({ checked }) => (checked ? "#FFE0EF" : "#fff")};
  border-radius: 8px;

  &:hover {
    background-color: ${({ checked, theme }) =>
      checked ? "#FFE0EF" : theme.colors.neutral[95]};
    border-radius: 8px;
  }
`;

export const MenuContainer = styled.ul<{ showClearButton?: boolean }>`
  list-style-type: none;
  max-height: 300px;
  width: 100%;
  margin: ${(props) => props.theme.spacings.xs5} 0px;
  overflow: ${({ showClearButton }) => (showClearButton ? "auto" : "visible")};
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const StyledLinkButton = styled(LinkButton)`
  &.MuiButtonBase-root {
    padding: 0px;
  }
`;

export const StyledMenuItemText = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary[40]};
`;
