import * as styled from "./styled";
import EmptyTableSvg from "../../assets/empty_table.svg";

export const PageError = () => {
  return (
    <styled.MainContainer>
      <styled.ItemsContainer>
        <EmptyTableSvg />
        <styled.Text variant={"body2"} children={`Organograma vazio`} />
      </styled.ItemsContainer>
    </styled.MainContainer>
  );
};
