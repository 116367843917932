import { Button, Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { segment } from "src/utils/segment";

type IToolButton = {
  segmentEvent: string;
  handleClick: () => void;
  loading?: boolean;
  disabled?: boolean;
  icon: string;
  text: string;
};

export const ToolbarButton = ({
  segmentEvent,
  handleClick,
  loading,
  disabled,
  icon,
  text,
}: IToolButton) => {
  return (
    <Button
      variant="tertiary"
      size="small"
      style={{ whiteSpace: "pre" }}
      onClick={() => {
        handleClick();
        segment({
          track: segmentEvent,
        });
      }}
      loading={loading}
      disabled={disabled}
    >
      <Typography variant="caption" weight={700}>
        {text}
      </Typography>
      <Icons name={icon as any} fill="transparent" />
    </Button>
  );
};
