import {
  Button,
  LinkButton,
  Tooltip,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const GradientBanner = styled.div<{ src?: string }>`
  display: flex;
  flex-direction: column;
  align-itemns: center;
  justify-content: center;
  align-items: center;
`;
// background-position: center;
// filter: blur(58px);
// background: ${({ src }) =>
//   src
//     ? `linear-gradient(180deg, rgba(255, 255, 255, 0.00) 34.86% , #FFF 57.07%), url(${src})`
//     : `linear-gradient(180deg, #FFCCE4 34.86%, #FFF 57.07%)`};

export const NameBanner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledTypography = styled(Typography)<{ customColor: number }>`
  color: ${({ theme, customColor }) => theme.colors.neutral[customColor]};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  gap: 16px;
`;

export const StyledButton = styled(Button)`
  height: 48px;
`;

export const BasicInfosContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
  padding: 24px 40px 16px 40px;
`;

export const DividerContainer = styled.div`
  padding: 0px 40px;
`;

export const LeadershipContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
  padding: 24px 40px 200px 40px;
`;

export const EmployeeFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 32px;
`;

export const StyledTooltip = styled(Tooltip)`
  & .MuiTooltip-tooltip {
    padding: 12px 16px;
  }
`;

export const StyledLinkButton = styled(LinkButton)``;
