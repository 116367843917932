import { BrowserRouter, Routes, Route } from "react-router-dom";

import { OpenRoutesGuard } from "./openRoutesGuard";
import { RoutesGuard } from "./permissionsRoutesGuard";
import { useRegisterRoutes } from "@flash-tecnologia/hros-web-utility";
import { PageMain, PageAddMultiplePeople } from "src/pages";

export const baseRoute = "/orgchart";

export const routes = {
  pageMain: `${baseRoute}`,
  pageAddMultiplePeople: `${baseRoute}/addMultiplePeople/:parentId/:parentName`,
} as const;

export const slugRoutes = [
  {
    slug: "people_orgchart_update",
    routes: [routes.pageAddMultiplePeople],
  },
];

const AppRouter = () => {
  useRegisterRoutes([
    {
      path: routes.pageMain,
      hideHeader: false,
    },
    {
      path: routes.pageAddMultiplePeople,
      hideHeader: true,
    },
  ]);

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<OpenRoutesGuard />}>
          <Route path={routes.pageMain} element={<PageMain />} />
        </Route>
        <Route element={<RoutesGuard />}>
          <Route
            path={routes.pageAddMultiplePeople}
            element={<PageAddMultiplePeople />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
