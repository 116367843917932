import styled from "styled-components";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

export const Container = styled.div<{ hasImage: boolean }>`
  width: 200px;
  min-width: 200px;
  width: 200px;
  min-height: 200px;
  height: 200px;
  background: ${({ hasImage, theme }) =>
    hasImage ? "#FFFFFF" : theme.colors.secondary[95]};
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 16px;
`;

export const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 100px;
`;

export const AvatarName = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary[50]};
`;
