import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import Empty from "../../../../../../../../assets/empty.png";
import { Container } from "./styled";

export const FilterEmptyState = () => {
  return (
    <Container>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img src={Empty} alt="sem resultados" />
      </div>
      <Typography variant="body3" weight={600}>
        Sua busca não retornou nenhum resultado.
      </Typography>
    </Container>
  );
};
