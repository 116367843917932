import { ButtonText, PopoverButton, StyledPopover } from "./styled";
import { Icons } from "@flash-tecnologia/hros-web-ui-v2";

interface IPopover {
  open: boolean;
  anchorEl: Element | ((element: Element) => Element) | null | undefined;
  onClose: () => void;
  option: string;
  onClick: (action: string) => void;
}

const popoverOptions = {
  add: {
    id: "isAddPopover",
    anchorOrigin: { vertical: "top", horizontal: "right" },
    transformOrigin: { vertical: "top", horizontal: "left" },
    options: [
      {
        icon: "IconUserPlus",
        text: "Adicionar várias pessoas",
        action: "addMultiplePeople",
      },
      { icon: "IconUsers", text: "Adicionar pessoa", action: "addPerson" },
    ],
  },
  edit: {
    id: "isEditPopover",
    anchorOrigin: { vertical: "top", horizontal: "right" },
    transformOrigin: { vertical: "top", horizontal: "left" },
    options: [
      { icon: "IconUser", text: "Substituir pessoa", action: "replacePerson" },
      { icon: "IconTrash", text: "Excluir pessoa", action: "deletePerson" },
    ],
  },
};

export const Popover = ({
  open,
  anchorEl,
  onClose,
  option,
  onClick,
}: IPopover) => {
  return (
    <StyledPopover
      id={popoverOptions[option]?.id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={popoverOptions[option]?.anchorOrigin}
      transformOrigin={popoverOptions[option]?.transformOrigin}
    >
      {popoverOptions[option]?.options?.map((opt, index) => {
        return (
          <PopoverButton
            key={index}
            onClick={() => onClick(opt?.action)}
            style={{
              background: opt?.text == "Excluir pessoa" ? "#FFECD6" : "#fff",
            }}
          >
            <Icons
              name={opt?.icon}
              size={16}
              color={opt?.text == "Excluir pessoa" ? "#C96C01" : "#53464F"}
            />
            <ButtonText
              variant="body3"
              weight={600}
              variantColor={
                opt?.text == "Excluir pessoa" ? "#C96C01" : "#53464F"
              }
            >
              {opt?.text}
            </ButtonText>
          </PopoverButton>
        );
      })}
    </StyledPopover>
  );
};
