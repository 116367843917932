import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { ModalHeaderDiv, StyledIconButton } from "./styled";

export const ModalHeader = ({
  closeModal,
  setSelectedLeader,
  setSelectedLevel,
  setLevelValue,
  setSelectedOption,
}) => {
  return (
    <ModalHeaderDiv>
      <Typography variant="headline6" weight={700}>
        Exportar organograma
      </Typography>
      <StyledIconButton
        size="small"
        variant="line"
        icon="IconX"
        onClick={() => {
          closeModal();
          setSelectedLeader(null);
          setSelectedLevel(null);
          setLevelValue({ value: 0, label: "Todos os níveis" });
          setSelectedOption("Organograma completo");
        }}
      />
    </ModalHeaderDiv>
  );
};
