import {
  Icons,
  ObjectLiteral,
  Tag,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Autocomplete, TextFieldProps, AutocompleteProps } from "@mui/material";
import React, { useEffect, useState } from "react";

import * as styled from "./styled";

import { avatarName } from "src/utils/avatarName";
import { segment } from "src/utils/segment";
import {
  GetRolesAndDepartmentsResponse,
  GetOrgchartResponseV2,
} from "server/src/services";

export type SearchProps = {
  getOrgChartV2Response?: GetOrgchartResponseV2;
  getRolesAndDepartmentsResponse?: GetRolesAndDepartmentsResponse;
  multiple?: boolean;
  error?: boolean;
  helperText?: string;
  noOptionsText?: string;
  onInputChange?: (
    event: React.SyntheticEvent,
    value?: string,
    reason?: string
  ) => void;
  onChange: (e: any, name: any) => void;
} & Omit<TextFieldProps, "variant" | "onChange"> &
  Omit<
    AutocompleteProps<ObjectLiteral, boolean, boolean, true>,
    "onBlur" | "onChange" | "renderInput" | "options"
  >;

export const Search = ({
  getOrgChartV2Response,
  getRolesAndDepartmentsResponse,
  disabled,
  multiple = false,
  onChange,
  onInputChange,
  helperText,
  error,
  ref,
  label,
  onBlur,
  className,
  onKeyDown,
  ...props
}: SearchProps) => {
  const [options, setOptions] = useState<any>([]);

  const highlightSearch = (label, inputValue) => {
    if (!inputValue) {
      return label;
    }

    const escapedInput = inputValue.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");

    const regex = new RegExp(`(${escapedInput})`, "gi");
    const parts = label.split(regex);

    return parts.map((part, index) =>
      regex.test(part) ? <strong key={index}>{part}</strong> : part
    );
  };

  const handleChange = (e, name) => {
    onChange && onChange(e, name);
    segment({
      track: `people_strategic_hr_orgchart_mainsearchbar_fulfilled`,
    });
  };

  useEffect(() => {
    let options: any = [];

    (getOrgChartV2Response as any)?.forEach((p) => {
      if (p?.cardType == "company") return;
      options.push({
        label: p?.name?.toLowerCase(),
        value: p?.id,
        type: "Pessoa",
        caption: p?.roles?.name,
        image: p?.profilePicture,
      });
    });

    getRolesAndDepartmentsResponse?.roles?.forEach((r) =>
      options.push({
        label: r?.label?.toLowerCase(),
        value: r?.value,
        type: "Cargo",
      })
    );

    getRolesAndDepartmentsResponse?.departments?.forEach((d) =>
      options.push({
        label: d?.label?.toLowerCase(),
        value: d?.value,
        type: "Departamento",
      })
    );

    setOptions(options);
  }, [getOrgChartV2Response, getRolesAndDepartmentsResponse]);

  return (
    <styled.Wrapper>
      <Autocomplete
        {...props}
        ref={ref}
        className={error ? `Mui-error ${className}` : `${className}`}
        disablePortal
        options={options}
        multiple={multiple}
        readOnly={disabled}
        disabled={disabled}
        freeSolo
        onInputChange={onInputChange}
        onSelect={(e: any) => handleChange(e, e?.target?.value)}
        filterOptions={(options, { inputValue }) =>
          options.filter(
            (item) =>
              item?.label?.toLowerCase()?.includes(inputValue?.toLowerCase()) ||
              item?.caption?.toLowerCase()?.includes(inputValue?.toLowerCase())
          )
        }
        popupIcon={<Icons name="IconChevronDown" fill="transparent" />}
        PopperComponent={(props: any) => (
          <>
            <styled.StyledPopper {...props} />
          </>
        )}
        renderInput={(params) => (
          <>
            <styled.SearchFieldIcon
              className="search-field-icon"
              position="start"
            >
              <Icons className="iconSearch" name="IconSearch" />
            </styled.SearchFieldIcon>
            <styled.StyledTextField
              {...params}
              onBlur={(e) => {
                onBlur && onBlur(e);
                handleChange(e, e);
              }}
              onChange={(e) => {
                handleChange(e, e);
              }}
              error={error}
              onKeyDown={onKeyDown}
              disabled={disabled}
              label={label}
              helperText={helperText}
              variant="filled"
            />
          </>
        )}
        renderOption={(props, option, { inputValue }) => {
          const isSelected = props["aria-selected"] as boolean;
          const isPerson = option?.type == "Pessoa";
          return (
            <>
              <li {...props}>
                <styled.SelectFieldOption className="select-field-option">
                  {Boolean(option?.icon) && (
                    <styled.SelectFieldOptionIcon className="select-field-option-icon">
                      {option?.icon}
                    </styled.SelectFieldOptionIcon>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    {isPerson ? (
                      <styled.Avatar hasImage={option?.image}>
                        {option?.image ? (
                          <styled.AvatarImage src={option?.image} />
                        ) : (
                          <styled.AvatarName variant="body1">
                            {avatarName(option?.label)}
                          </styled.AvatarName>
                        )}
                      </styled.Avatar>
                    ) : (
                      <></>
                    )}
                    <div>
                      <Typography
                        variant="body3"
                        weight={600}
                        style={{ textTransform: "capitalize" }}
                      >
                        {highlightSearch(option?.label, inputValue)}
                      </Typography>

                      {Boolean(option?.caption) && isPerson ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "4px",
                          }}
                        >
                          <Icons
                            name="IconBriefcase"
                            size={16}
                            color={isSelected ? "#F20D7A" : "#83727D"}
                          />
                          <Typography
                            variant="body4"
                            weight={600}
                            color={isSelected ? "#F20D7A" : "#83727D"}
                          >
                            {highlightSearch(option?.caption, inputValue)}
                          </Typography>
                        </div>
                      ) : (
                        <>
                          <styled.SelectFieldOptionCaption className="select-field-option-caption">
                            {option?.caption}
                          </styled.SelectFieldOptionCaption>
                        </>
                      )}
                    </div>
                  </div>
                  <div>
                    {Boolean(option?.type) && !isSelected ? (
                      <Tag
                        variant="secondary"
                        style={{ pointerEvents: "none", cursor: "default" }}
                      >
                        <Typography variant="body4" weight={600}>
                          {option?.type}
                        </Typography>
                      </Tag>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "12px",
                        }}
                      >
                        <Typography
                          variant="body4"
                          weight={600}
                          color="#F20D7A"
                        >
                          {option?.type}
                        </Typography>
                        <Icons name="IconCheck" color="#F20D7A" size={24} />
                      </div>
                    )}
                  </div>
                </styled.SelectFieldOption>
              </li>
            </>
          );
        }}
      />
    </styled.Wrapper>
  );
};
