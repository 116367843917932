import { Icons } from "@flash-tecnologia/hros-web-ui-v2";

interface IExpandExportButton {
  directSubordinates: number;
  totalSubordinates: number;
  children: any[];
  node: any;
}

export const ExpandExport = ({
  directSubordinates,
  totalSubordinates,
  children,
  node,
}: IExpandExportButton) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        pointerEvents: "all",
      }}
    >
      {node && (
        <div
          style={{
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "4px 8px",
            width: "auto",
            height: "28px",
            margin: "auto",
            minHeight: "28px",
            background: "#ffffff",
            border: "1px solid #ebe6e9",
            borderRadius: "12px",
            position: "fixed",
          }}
        >
          <Icons
            name={"IconUser"}
            fill="#FFFFFF"
            color="#B9ACB4"
            width={"18px"}
          />
          <span
            style={{
              fontWeight: 600,
              color: "#b9acb4",
              marginLeft: "4px",
              marginTop: "2px",
            }}
          >
            {directSubordinates}
          </span>
          <Icons
            name={"IconUsers"}
            fill="#FFFFFF"
            color="#B9ACB4"
            width={"18px"}
            style={{ marginLeft: "14px" }}
          />
          <span
            style={{
              fontWeight: 600,
              color: "#b9acb4",
              marginLeft: "4px",
              marginTop: "2px",
            }}
          >
            {totalSubordinates}
          </span>
          <Icons
            name={children ? "IconChevronUp" : "IconChevronDown"}
            fill="#FFFFFF"
            color="#B9ACB4"
            width={"16px"}
            style={{ marginLeft: "6px" }}
          />
        </div>
      )}
    </div>
  );
};
