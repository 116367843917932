import {
  Divider,
  LinkButton,
  SearchField,
  Toggle,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import {
  ButtonSave,
  CloseIcons,
  Content,
  FooterContainer,
  HeaderContainer,
  IconWarning,
  IconWarningContainer,
  MessageContainer,
  ModalStyled,
  TextContainerWarning,
  ToggleContainer,
  ToggleMessage30,
  ToggleMessage50,
  WarningMessage,
} from "./styled";
import { Dispatch, SetStateAction, useState } from "react";
import { segment } from "src/utils/segment";
import { trpc } from "@api/client";
import { debounce } from "lodash-es";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import dispatchToast from "src/utils/dispatchToast";

export interface TModalAddPerson {
  handlePersonAlreadyInOrgchart: (employeeId: string) => boolean | undefined;
  handlePersonHasChildren: (employeeId: string) => boolean | undefined;
  modalAddPerson: {
    open: boolean;
    id: string;
    type: string;
    keepChildrens: boolean;
    info: any;
  };
  setModalAddPerson: Dispatch<
    SetStateAction<TModalAddPerson["modalAddPerson"]>
  >;
  refetch: () => void;
  orgchartRef: any;
}

export const ModalAddPerson = ({
  handlePersonAlreadyInOrgchart,
  handlePersonHasChildren,
  setModalAddPerson,
  modalAddPerson,
  refetch,
  orgchartRef,
}: TModalAddPerson) => {
  const { selectedCompany } = useSelectedCompany();

  const currentCompanyId = selectedCompany?.id;
  const [employeeSelected, setEmployeeSelected] = useState<any>("");
  const [foundEmployeeAtOrgchat, setFoundEmployeeAtOrgchat] =
    useState<boolean>(false);
  const [employeeOptions, setEmployeeOptions] = useState<any>([]);
  const [employeeHasChildren, setEmployeehasChildren] =
    useState<boolean>(false);

  const getEmployeesToAdd = trpc.getEmployees.useMutation({
    onSuccess: async (data, { query }) => {
      if (!query?.length) {
        setEmployeeOptions([]);
        return;
      }

      setEmployeeOptions(data?.employees);
    },
    onError: () => ({}),
  });

  const handleSearchEmployees = debounce((value) => {
    getEmployeesToAdd.mutate({ companyId: currentCompanyId, query: value });
  }, 100);

  const onSelectPersonToAdd = (value) => {
    setEmployeeSelected(value);

    const found = handlePersonAlreadyInOrgchart(value);
    if (found) setFoundEmployeeAtOrgchat(true);
    else setFoundEmployeeAtOrgchat(false);

    const hasChidlren = handlePersonHasChildren(value);
    if (hasChidlren) setEmployeehasChildren(true);
    else setEmployeehasChildren(false);
  };

  const handleClose = () => {
    setEmployeeSelected("");
    setModalAddPerson({
      open: false,
      id: "",
      type: "",
      info: {},
      keepChildrens: true,
    });
    setFoundEmployeeAtOrgchat(false);
  };

  const addPersonToOrgchart = trpc.addPersonToOrgChart.useMutation({
    onSuccess: async () => {
      refetch();
      handleClose();
      dispatchToast({
        type: "success",
        content: "Pessoa adicionada ao organograma com sucesso",
      });
    },
    onError: () => {
      dispatchToast({
        type: "warning",
        content: "Não foi possível adicionar essa pessoa ao organograma",
      });
    },
  });

  const addEditEmployeeToTree = async () => {
    try {
      let objToSend = {
        levelToSave:
          modalAddPerson.id === "first" ? 0 : modalAddPerson?.info?.levelToSave,
        parentId:
          modalAddPerson.id === "first"
            ? ""
            : modalAddPerson?.info?.parentIdOrPersonReplaced,
        employeeId: employeeSelected,
        keepChildrens: modalAddPerson.keepChildrens,
      } as any;
      addPersonToOrgchart.mutate({
        body: objToSend,
        companyId: currentCompanyId,
      });
      orgchartRef?.current?.expandAll()?.fit();
    } catch (err) {}
  };

  return (
    <ModalStyled
      open={modalAddPerson.open}
      onClose={() => {
        handleClose();
      }}
    >
      <div>
        <HeaderContainer>
          <CloseIcons
            onClick={() => {
              setEmployeeSelected("");
              setModalAddPerson({
                open: false,
                id: "",
                type: "",
                info: {},
                keepChildrens: true,
              });
              setFoundEmployeeAtOrgchat(false);
            }}
          >
            x
          </CloseIcons>
          <Typography variant="headline6">Adicionar uma pessoa</Typography>
          <Typography variant="body3" style={{ marginTop: "8px" }}>
            Escolha quem gostaria de atribuir à posição que você escolheu no
            organograma
          </Typography>
        </HeaderContainer>
        <Divider orientation="horizontal" />
        <Content>
          <Typography
            variant="body3"
            style={{ fontWeight: "600", marginBottom: "16px" }}
          >
            Atribuição no organograma
          </Typography>
          <SearchField
            label="Selecione a pessoa"
            loading={getEmployeesToAdd?.isLoading}
            loadingText={"Carregando..."}
            onSearchChange={(_, value) => {
              onSelectPersonToAdd(value?.value);
            }}
            onInputChange={(_, value) => {
              handleSearchEmployees(value);
              setEmployeeSelected("");
            }}
            options={employeeOptions?.map((t: any) => {
              return { label: t?.label?.toLowerCase(), value: t?.value };
            })}
          />
          {foundEmployeeAtOrgchat && (
            <>
              <MessageContainer>
                <IconWarningContainer>
                  <IconWarning name="IconAlertCircle" fill="transparent" />
                </IconWarningContainer>
                <TextContainerWarning>
                  <WarningMessage variant="body3" style={{ fontWeight: "600" }}>
                    Essa pessoa já está associada ao Organograma
                  </WarningMessage>
                  <WarningMessage variant="body4">
                    Ao continuar, você irá movê-la para uma nova posição
                  </WarningMessage>
                </TextContainerWarning>
              </MessageContainer>

              {employeeHasChildren && (
                <ToggleContainer>
                  <Toggle
                    checked={modalAddPerson.keepChildrens}
                    onChange={(e, value) =>
                      setModalAddPerson({
                        ...modalAddPerson,
                        keepChildrens: value,
                      })
                    }
                  />

                  <TextContainerWarning>
                    <ToggleMessage30
                      variant="body3"
                      style={{ fontWeight: "600" }}
                    >
                      Mover também os liderados associados
                    </ToggleMessage30>
                    <ToggleMessage50 variant="body4">
                      Caso essa opção esteja desmarcada, as pessoas associadas à
                      esse líder perderão o vínculo e você deverá associar um
                      novo líder posteriormente
                    </ToggleMessage50>
                  </TextContainerWarning>
                </ToggleContainer>
              )}
            </>
          )}
        </Content>

        <Divider orientation="horizontal" />

        <FooterContainer>
          <LinkButton
            variant={"secondary"}
            style={{ alignSelf: "center" }}
            onClick={() =>
              setModalAddPerson({
                open: false,
                id: "",
                type: "",
                info: {},
                keepChildrens: true,
              })
            }
          >
            Cancelar
          </LinkButton>
          <ButtonSave
            variant="primary"
            size="large"
            loading={addPersonToOrgchart.isLoading}
            disabled={addPersonToOrgchart.isLoading}
            onClick={() => {
              addEditEmployeeToTree();
              if (modalAddPerson.type === "add") {
                segment({
                  track: `people_strategic_hr_orgchart_addoneperson_option_clicked`,
                });
              } else {
                segment({
                  track: `people_strategic_hr_orgchart_replaceperson_option_clicked`,
                });
              }
            }}
          >
            Salvar
          </ButtonSave>
        </FooterContainer>
      </div>
    </ModalStyled>
  );
};
