import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-itemns: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(58px);
  background: linear-gradient(
    180deg,
    #e6e6e6 0%,
    rgba(230, 230, 230, 0) 81.53%
  );
  z-index: -1;
  padding: 24px 0px;
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 32px;
  padding: 0px 40px 200px 40px;
`;

export const CenteredSkeletonDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 11px;
  margin-top: 16px;
  margin-bottom: 32px;
`;

export const MiddleDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top:42px;
  margin-bottom 32px;
  padding: 0px 40px;
`;

export const SpaceBetweenDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

export const GapDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
`;
