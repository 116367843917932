import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  pointer-events: all;
`;

export const ExpandButtonContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  width: auto;
  height: 28px;
  margin: auto;
  min-height: 28px;
  background: #ffffff;
  border: 1px solid #ebe6e9;
  border-radius: 12px;
  position: fixed;
`;

export const IconNumber = styled(Typography)`
  font-weight: 600 !important;
  color: #b9acb4;
  margin-left: 4px;
  margin-top: 2px;
`;

export const AddUnderIcon = styled.div<{
  hasChildren: boolean;
  permission: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: ${({ permission }) => (permission ? "visible" : "hidden")};
  margin-top: ${({ hasChildren }) => (!hasChildren ? "48px" : "0px")};

  ${ButtonContainer}:hover & {
    opacity: 1;
  }
`;

export const AddIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: #f8f6f8;
  border-radius: 40px;
`;
