import styled from "styled-components";
import { IconButton } from "@flash-tecnologia/hros-web-ui-v2";

export const StyledIconButton = styled(IconButton)``;

export const ModalHeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 44px 56px 32px 56px;
  border-bottom: 1px solid #ebe6e9;
`;
