import {
  Button,
  Icons,
  Modal,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const ModalStyled = styled(Modal)`
  && {
    .modal-content-area {
      padding: 0px;
    }
  }

  .MuiPopover-root {
    width: 871px;
    height: 460px;
    padding: 44px 0px 0px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
  }
`;

export const CloseIcons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  border: 1px solid #d1c7ce;
  border-radius: 40px;
  margin-left: auto;
  cursor: pointer;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 44px 72px;
`;

export const TextContainer = styled.div`
  width: 420px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const AlertContainer = styled.div`
  width: 80px;
  height: 80px;
  background: ${({ theme }) => theme.colors.feedback.error[90]};
  border-radius: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
`;

export const AlertIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.feedback.error[40]};
`;

export const AttentionText = styled(Typography)`
  color: ${({ theme }) => theme.colors.feedback.error[40]};
  font-weight: 700 !important;
`;

export const ModalTextExplanation = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
  text-align: center;
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 22px 44px;
`;

export const StyledButton = styled(Button)`
  && {
    &.MuiButtonBase-root.size-large {
      background: ${({ theme }) => theme.colors.feedback.error[90]};

      &:hover {
        background: ${({ theme }) => theme.colors.feedback.error[90]};
        box-shadow: 0px 16px 40px rgb(203, 109, 1);
      }

      &.loading {
        pointer-events: none;
        user-select: none;
        animation-duration: 6s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: wave;
        animation-timing-function: linear;
        color: var(--color-feedback-error-pure);
        background: linear-gradient(
          to right,
          transparent,
          var(--color-feedback-error-light1) 50%,
          var(--color-feedback-error-light1) 80%
        );
      }
    }
  }
`;
