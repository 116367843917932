import { Skeleton, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import {
  ButtonsContainer,
  Container,
  StyledDrawer,
  StyledIconButton,
  TitleContainer,
} from "./styled";

export const Drawer = ({
  onClose,
  open,
  children,
  title,
  headerButton,
  loading,
}) => {
  return (
    <StyledDrawer anchor="right" onClose={() => onClose()} open={open}>
      <Container>
        <TitleContainer>
          {loading ? (
            <>
              <Skeleton
                variant="rectangular"
                sx={{ borderRadius: "25px" }}
                width={57}
                height={28}
              />
              <Skeleton variant="circular" width={8} height={8} />
            </>
          ) : (
            <>
              <ButtonsContainer>
                {headerButton && headerButton}
                <Typography variant="headline7" weight={700}>
                  {title}
                </Typography>
              </ButtonsContainer>
              <StyledIconButton
                size="small"
                variant="line"
                icon="IconX"
                onClick={() => {
                  onClose();
                }}
              />
            </>
          )}
        </TitleContainer>
        {children}
      </Container>
    </StyledDrawer>
  );
};
