type ICard = {
  image: string;
  name: string;
  role: string;
  nameInitials: string;
};

export const CardExport = ({ image, name, role, nameInitials }: ICard) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        height: "100%",
        background: "transparent",
      }}
    >
      <div
        style={{
          height: "28px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          background: "none",
          minHeight: "28px",
        }}
      >
        <div
          style={{
            background: "#fff",
            width: "56px",
            height: "56px",
            borderRadius: "100px",
            zIndex: 99,
          }}
        >
          <div
            style={{
              width: "56px",
              minWidth: "56px",
              minHeight: "56px",
              height: "56px",
              background: image ? "#FFFFFF" : "#FFE0EF",
              border: image ? "1px solid #FFFFFF" : "3.75px solid #FFFFFF",
              borderRadius: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "fixed",
            }}
          >
            {image ? (
              <img
                alt={`${name}`}
                src={image}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  borderRadius: "100px",
                }}
              />
            ) : (
              <span
                style={{ color: "#f20d7a", fontSize: "24px", fontWeight: 500 }}
              >
                {nameInitials}
              </span>
            )}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          height: "100%",
          background: "#ffffff",
          padding: "0px 24px 24px",
          borderTop: "2px solid #fe2b8f",
          boxShadow: "0px 4px 26px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
        }}
      >
        <div
          style={{
            width: "100%",
            marginBottom: "4px",
            marginTop: "45px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              textAlign: "center",
              fontWeight: 700,
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textTransform: "capitalize",
              color: "#333333",
            }}
          >
            {name}
          </span>
        </div>
        <div
          style={{
            width: "100%",
            marginBottom: "4px",
            minHeight: "20px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              textAlign: "center",
              fontWeight: 700,
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              color: "#83727d",
            }}
          >
            {role}
          </span>
        </div>
      </div>
    </div>
  );
};
