import styled from "styled-components";
import {
  Button,
  IconButton,
  Modal,
  SelectField,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Grid } from "@mui/material";

export const StyledModal = styled(Modal)`
  && {
    .modal-content-area {
      padding: 0px;
    }

    .modal-container {
      width: 150vw;
      height: 150vh;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 32px;
`;

export const OrgchartContainer = styled.div`
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  // border: 1px solid red;
`;

export const ConfigContainer = styled.div`
  display: flex;
  padding-top: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
`;

export const VisualizeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  border: 1px solid #d1c7ce;
  // min-height: 520px;
  height: 614px;
  overflow: hidden;
`;

export const OptionsDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledSelectField = styled(SelectField)`
  width: 100%;
`;

export const StyledGridContainer = styled(Grid)`
  display: flex;
  // flex-direction: column;
  padding: 24px 56px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 32px;
  align-self: stretch;
  width: 100%;
`;

export const StyledGridItem = styled(Grid)`
  width: 45%;
`;
export const StyledGridItem2 = styled(Grid)`
  width: 50%;
`;
