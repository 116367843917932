import styled from "styled-components";
import {
  Typography,
  SelectField,
  TransferList,
} from "@flash-tecnologia/hros-web-ui-v2";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 70px;
  padding: 20px 24px;
  background: #ffffff;
  border-bottom: 1px solid #ebe6e9;
  gap: 20px;
  width: 100%;
  position: absolute;
`;

export const GoBackIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid #d1c7ce;
  border-radius: 40px;
  cursor: pointer;
  margin-right: 30px;
`;

export const StepsHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 44px;
  height: 80px;
  padding: 0px 29px;
`;

export const StepAndDescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
`;

export const StepIcon = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  background: ${({ selected }) => (selected ? "#F20D7A" : "#F8F6F8")};
  border: 1px solid ${({ selected }) => (selected ? "#F20D7A" : "#F8F6F8")};
  border-radius: 50px;
  font-weight: 700;
  font-size: 8px;
  line-height: 12px;
  color: ${({ selected }) => (selected ? "#FFFFFF" : "#83727D")};
`;

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 40px 29px 0px;
  display: flex;
  flex-direction: row;
  margin-top: 70px;
  padding-bottom: 20px;
  overflow: auto;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AddMutlipleText = styled(Typography)`
  font-weight: 700 !important;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.neutral[50]} !important;
`;

export const FooterContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  width: 100%;
  height: 90px;
  background: #ffffff;
  border-top: 1px solid #ebe6e9;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  height: 100%;
`;

export const TitleDescription = styled(Typography)`
  font-weight: 700 !important;
  line-height: 38px !important;
  margin-bottom: 48px !important;
  color: ${({ theme }) => theme.colors.neutral[20]} !important;
`;

export const SubTitleDescription = styled(Typography)`
  font-weight: 700 !important;
  line-height: 22px !important;
  color: #f20d7a !important;
`;

export const DescriptionText = styled(Typography)`
  width: 300px;
  margin-top: 8px;
  font-weight: 400 !important;
  line-height: 22px !important;
  color: ${({ theme }) => theme.colors.neutral[50]} !important;
`;

export const ListTransferContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  margin-left: 24px;
  height: 100%;
  overflow: auto;
`;

export const TextList = styled(Typography)`
  font-weight: 700 !important;
  line-height: 22px !important;
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const SelectFieldStyled = styled(SelectField)`
  margin-top: 16px;
  margin-bottom: 40px;
  max-width: 450px;
  width: 450px;
  & .select-field-popper-custom {
    z-index: 3;
  }
`;

export const TransferListStyled = styled(TransferList)`
  width: 1000px;
`;
