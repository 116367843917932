import debounce, { DebouncedFunc } from "lodash-es/debounce";
import { useEffect, useState } from "react";

export type DebounceStateType = DebouncedFunc<
  (newState: string, callback: () => void) => void
>;

export const useDebounceState = (
  initialValue: string,
  delay: number
): [string, DebounceStateType] => {
  const [state, setState] = useState(initialValue);

  const debouncedSetState = debounce((newState, callback) => {
    setState(newState);
    callback && callback();
  }, delay);

  useEffect(() => {
    return () => {
      debouncedSetState.cancel();
    };
  }, [debouncedSetState]);

  return [state, debouncedSetState];
};
