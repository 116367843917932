import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Popover } from "@mui/material";
import styled from "styled-components";

export const StyledPopover = styled(Popover)`
  .MuiPaper-root {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    width: 320px;
    height: 140px;
    background: ${({ theme }) => theme.colors.neutral[100]};
    box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    gap: 4px;
  }
`;

export const PopoverButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  width: 296px;
  height: 56px;
  border-radius: 8px;
  gap: 8px;
  cursor: pointer;

  :hover {
    background: ${({ theme }) => theme.colors.neutral[95]};
  }
`;

export const ButtonText = styled(Typography)`
  //   color: ${({ theme }) => theme.colors.neutral[30]};
`;
