import { TextField, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { InputAdornment, Popper } from "@mui/material";
import styled from "styled-components";

export const SelectFieldOption = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

export const SelectFieldOptionIcon = styled.div`
  margin-right: 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  height: min-content;
  fill: ${({ theme }) => theme.colors.secondary[40]};
`;

export const SelectFieldOptionCaption = styled.div`
  color: #83727d;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
`;

export const SearchFieldIcon = styled(InputAdornment)`
  position: relative;
  top: 30px;
  left: 20px;
  fill: transparent;
  z-index: 1;
  .iconSearch {
    stroke: ${({ theme }) => theme.colors.neutral[50]};
  }
`;

export const StyledPopper = styled(Popper)`
  z-index: 3;

  .MuiAutocomplete-listbox {
    margin: 6px 0;

    .MuiAutocomplete-option {
      border-radius: 8px;
      margin: 6px 12px;
      padding: 16px;
      text-align: left;

      &[aria-selected="true"] {
        color: ${({ theme }) => theme.colors.secondary[40]};
        font-weight: 700;

        .select-field-option {
          color: ${({ theme }) => theme.colors.secondary[40]};

          .select-field-option-icon {
            fill: ${({ theme }) => theme.colors.secondary[40]};
          }

          .select-field-option-caption {
            color: ${({ theme }) => theme.colors.neutral[60]};
          }
        }
      }
    }
  }
`;

export const StyledTextField: any = styled(TextField)`
  .MuiFilledInput-root {
    border-radius: ${({ theme }) => theme.borders.radius.pill};
  }

  .MuiInputLabel-root {
    padding-left: 42px;
  }

  .MuiInputLabel-shrink {
    padding-left: 56px;
  }

  input {
    margin-left: 42px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 500px;

  .Mui-focused {
    .iconSearch {
      stroke: var(--color-secondary-light5);
      stroke: ${({ theme }) => theme.colors.secondary[70]};
    }
  }

  .Mui-disabled {
    .iconSearch {
      stroke: ${({ theme }) => theme.colors.neutral[80]};
    }
  }

  .Mui-error {
    .iconSearch {
      stroke: ${({ theme }) => theme.colors.feedback.error[50]};
    }
  }
`;

export const Avatar = styled.div<{ hasImage: boolean }>`
  width: 40px;
  min-width: 40px;
  width: 40px;
  min-height: 40px;
  height: 40px;
  background: ${({ hasImage, theme }) =>
    hasImage ? "#FFFFFF" : theme.colors.secondary[95]};
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 100px;
`;

export const AvatarName = styled(Typography)`
  font-weight: 700 !important;
  color: ${({ theme }) => theme.colors.secondary[50]};
`;
