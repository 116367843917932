import { formatRegistrationNumber } from "src/utils/formatRegistrationNumber";

export const CompanyCardExport = ({ registrationNumber, name, image }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        height: "80%",
        background: "transparent",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          height: "100%",
          background: "#ffffff",
          padding: "16px 38px",
          gap: "8px",
          borderTop: "2px solid #fe2b8f",
          boxShadow: "0px 4px 26px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
        }}
      >
        {image ? (
          <img
            alt={`${name}`}
            src={image}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "160px",
              objectFit: "cover",
              height: "50%",
            }}
          />
        ) : (
          <div
            style={{
              width: "100%",
              marginBottom: "4px",
              marginTop: "24px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <span
              style={{
                fontSize: "16px",
                textAlign: "center",
                fontWeight: 700,
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                color: "#333333",
              }}
            >
              {name}
            </span>
          </div>
        )}

        <div
          style={{
            width: "100%",
            marginBottom: "4px",
            minHeight: "20px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              textAlign: "center",
              fontWeight: 700,
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              color: "#83727d",
            }}
          >
            {`CNPJ ${formatRegistrationNumber(registrationNumber)} `}
          </span>
        </div>
      </div>
    </div>
  );
};
