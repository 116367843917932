import { Checkbox, Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Divider } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { segment } from "../../../../../../../../utils/segment";
import { FilterEmptyState } from "../FilterEmptyState";
import {
  ClearButtonContainer,
  MenuContainer,
  MenuItem,
  SelectAllContainer,
  SelectAllItem,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledContainerIcon,
  StyledDiv,
  StyledLinkButton,
  StyledMenuItemText,
  StyledSearchField,
  StyledTitle,
} from "./styled";

interface Option {
  label: string;
  value: string;
  checked?: boolean;
  setChecked?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface FilterAccordionProps {
  title: string;
  options: Option[];
  onClick: (selectedOptions: string[]) => void;
  initiallySelectedOptions: string[];
}

export const FilterAccordion = ({
  title,
  options,
  onClick,
  initiallySelectedOptions,
}: FilterAccordionProps) => {
  const optionsState = options.map((option) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [checked, setChecked] = useState(
      initiallySelectedOptions.includes(option.value)
    );

    return {
      ...option,
      checked: option.checked || checked,
      setChecked: option.setChecked || setChecked,
    };
  });
  const [search, setSearch] = useState<string>("");
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(true);

  const handleClick = (newState: boolean, option: Option) => {
    const index = optionsState?.findIndex((item) => item == option);

    optionsState[index].setChecked(newState);
  };

  const handleSelectAll = () => {
    const filteredOptions = optionsState.filter((option) =>
      option?.label?.toLowerCase().includes(search?.toLowerCase())
    );

    const anyUnchecked = filteredOptions.some((option) => !option.checked);

    filteredOptions.forEach((option) => option.setChecked(anyUnchecked));

    setCheckedAll(!anyUnchecked);
  };

  const handleClear = () => {
    optionsState.forEach((option) => option.setChecked(false));
    setCheckedAll(false);
  };

  const filteredOptions = useMemo(() => {
    return optionsState.filter((option) =>
      option?.label?.toLowerCase().includes(search?.toLowerCase())
    );
  }, [optionsState, search]);

  const mappedCheckedOptions = optionsState.map(({ checked }) => checked);

  useEffect(() => {
    const optionsChecked = optionsState.reduce(
      (labelOptions: any, { checked, value }) => {
        if (checked) {
          labelOptions.push(value);
        }

        return labelOptions;
      },
      []
    );

    onClick(optionsChecked);

    const anyUnchecked = optionsState.some((option) => !option.checked);

    setCheckedAll(!anyUnchecked);

    const allChecked = optionsState
      .filter((option) =>
        option?.label?.toLowerCase().includes(search?.toLowerCase())
      )
      .every((option) => option.checked);

    if (allChecked) {
      setCheckedAll(true);
    }
  }, [...mappedCheckedOptions, search]);

  return (
    <StyledAccordion
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
    >
      <StyledAccordionSummary
        onClick={() => ({})}
        expandIcon={
          <StyledContainerIcon>
            <Icons name="IconChevronDown" fill="transparent" />
          </StyledContainerIcon>
        }
      >
        <StyledTitle variant="headline8">{title}</StyledTitle>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <Divider />
        <StyledDiv>
          <StyledSearchField
            label={`Buscar por ${title}`}
            InputProps={{
              disableUnderline: true,
            }}
            onChange={(e) => {
              setSearch(e.target.value);
              if (title == "Cargos") {
                segment({
                  track: `people_strategic_hr_orgchart_filter_button_filters_drawer_position_accordion_searchbar_fulfilled`,
                });
              } else {
                segment({
                  track: `people_strategic_hr_orgchart_filter_button_filters_drawer_department_accordion_searchbar_fulfilled`,
                });
              }
            }}
          />
          <SelectAllContainer showSelectAllButton={true}>
            <SelectAllItem>
              <Checkbox
                checked={checkedAll}
                onChange={() => handleSelectAll()}
              />
              <Typography variant="body3" color="#53464F" weight={600}>
                Selecionar todos
              </Typography>
            </SelectAllItem>
          </SelectAllContainer>
          {filteredOptions.length ? (
            <MenuContainer showClearButton={true}>
              {filteredOptions.map((option, index) => (
                <MenuItem
                  onClick={() => handleClick(!option.checked, option)}
                  key={index}
                  checked={option.checked}
                >
                  <Checkbox
                    checked={option.checked}
                    onChange={() => {
                      handleClick(!option.checked, option);
                      if (title == "Cargos") {
                        segment({
                          track: `people_strategic_hr_orgchart_filter_button_filters_drawer_position_accordion_checkbox_selected`,
                        });
                      } else {
                        segment({
                          track: `people_strategic_hr_orgchart_filter_button_filters_drawer_department_accordion_checkbox_selected`,
                        });
                      }
                    }}
                  />

                  {option.checked ? (
                    <StyledMenuItemText variant="body3" weight={600}>
                      {option.label}
                    </StyledMenuItemText>
                  ) : (
                    <Typography variant="body3" weight={600}>
                      {option.label}
                    </Typography>
                  )}
                </MenuItem>
              ))}
            </MenuContainer>
          ) : (
            <FilterEmptyState />
          )}

          <ClearButtonContainer>
            <StyledLinkButton variant="primary" onClick={handleClear}>
              Limpar seleção
            </StyledLinkButton>
          </ClearButtonContainer>
        </StyledDiv>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};
