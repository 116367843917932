import styled from "styled-components";
import { Button } from "@flash-tecnologia/hros-web-ui-v2";

export const ModalFooterDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px 56px;
  border-top: 1px solid #ebe6e9;
`;

export const StyledButton = styled(Button)``;
