import { IconButton, Button } from "@flash-tecnologia/hros-web-ui-v2";
import { Drawer } from "@mui/material";
import styled from "styled-components";

export const Container = styled.div`
  width: 500px;
  margin: 120px 0;
`;

export const TitleContainer = styled.div`
  padding: 32px 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #ebe6e9;

  position: fixed;
  width: inherit;
  top: 0;
  background: white;
  z-index: 10;
`;

export const FooterContainer = styled.div`
  padding: 32px 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ebe6e9;
  position: fixed;
  width: inherit;
  bottom: 0;
  background: white;
  z-index: 10;
`;

export const FiltersContainer = styled.div`
  padding: 32px 40px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledDrawer = styled(Drawer)``;

export const StyledIconButton = styled(IconButton)``;

export const StyledButton = styled(Button)``;
