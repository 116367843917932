import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import {
  LinkButton,
  Icons,
  Loader,
  Button,
} from "@flash-tecnologia/hros-web-ui-v2";
import dispatchToast from "../../utils/dispatchToast";

import {
  Container,
  HeaderContainer,
  GoBackIcon,
  AddMutlipleText,
  MainContainer,
  DescriptionContainer,
  FooterContainer,
  ButtonsContainer,
  TitleDescription,
  SubTitleDescription,
  DescriptionText,
  ListTransferContainer,
  TextList,
  SelectFieldStyled,
  TransferListStyled,
} from "./styled";
import { trpc } from "@api/client";
import { segment } from "../../utils/segment";
import _ from "lodash";
import { routes } from "@routes";
import { useDebounceState } from "src/hooks/useDebounceState";

const PAGE_SIZE_DEFAULT_STEP = 50;

export const PageAddMultiplePeople = () => {
  const navigate = useNavigate();
  const { selectedCompany } = useSelectedCompany();
  const { parentId } = useParams();

  const currentCompanyId = selectedCompany?.id;

  const [paginationState, setPaginationState] = useState({
    pageNumber: 1,
    index: 0,
    pageSize: PAGE_SIZE_DEFAULT_STEP,
  });

  const [leftListSearch, setLeftListSearch] = useDebounceState("", 300);
  const [leftListData, setLeftListData] = useState<any>([]);
  const [rightListData, setRightListData] = useState<any>();

  const [availableEmployees, setAvailableEmployees] = useState<any>([]);
  const [totalEmployees, setTotalEmployees] = useState<number>(0);

  trpc.useQueries((t) => [
    t.getOrgchartByLeaderV2(
      {
        leaderId: parentId ?? "",
        levelsToExport: 0,
        companyId: currentCompanyId,
      },
      {
        retry: false,
        refetchOnWindowFocus: false,
        onError: () => {
          dispatchToast({
            type: "error",
            content:
              "Sentimos muito, ocorreu um erro ao buscar os colaboradores disponíveis para serem adicionados.",
          });
        },
        onSuccess: (response) => {
          setRightListData(
            response?.orgchart
              ?.map((item) => ({
                value: item?.id,
                label: item?.name,
                _id: item?.id,
                checked: false,
                hidden: false,
              }))
              ?.filter((item) => item._id !== parentId)
          );
        },
      }
    ),
  ]);

  const getEmployeesToAdd = trpc.getEmployees.useMutation({
    onSuccess: (data) => {
      setAvailableEmployees(data?.employees);
      setTotalEmployees(data?.metadaEmployees?.totalCount);

      setLeftListData(
        data?.employees
          ?.map((item) => {
            return {
              ...item,
              _id: item.value,
              checked: false,
              hidden: false,
            };
          })
          ?.filter((item) => item._id != parentId)
      );
    },
  });

  const addMultiplePersonToOrgchart =
    trpc.addMultiplePersonToOrgChart.useMutation({
      onSuccess: async () => {
        dispatchToast({
          type: "success",
          content: "Pessoas adicionadas ao organograma com sucesso",
        });
        setTimeout(() => (window.location.href = "/orgchart"), 800);
      },
      onError: () => {
        dispatchToast({
          type: "warning",
          content: "Não foi possível adicionar essas pessoas ao organograma",
        });
      },
    });

  const handleLeftListSearch = (result) => {
    setLeftListSearch(result, () => {
      getEmployeesToAdd.mutate({ companyId: currentCompanyId, query: result });
    });
  };

  const filteredLeftList = useMemo(
    () =>
      leftListData?.filter(
        (employee) => !rightListData?.some((e) => e._id === employee._id)
      ),
    [leftListData, rightListData]
  );

  const columns = [
    {
      Header: "Nome",
      accessor: "label",
    },
  ];

  const saveMultiplePeople = async () => {
    try {
      let objToSend = {
        parentId: parentId,
        levelToSave: 1,
        keepChildrens: true,
        childrens: rightListData,
      } as any;

      if (rightListData?.length > 0) {
        addMultiplePersonToOrgchart.mutate({
          body: objToSend,
          companyId: currentCompanyId,
        });
      } else {
        dispatchToast({
          type: "warning",
          content: "É preciso selecionar pelo menos 1 pessoa",
        });
      }
    } catch (err) {
      dispatchToast({
        type: "error",
        content: "Erro ao salvar várias pessoas",
      });
    }
  };

  const onScrollReachEnd = (hasReachedEnd: boolean) => {
    const scrollReachedEnd =
      availableEmployees?.length < totalEmployees && hasReachedEnd;
    const lastCellIsValidEmployee =
      typeof availableEmployees?.[availableEmployees.length - 1]?.label ===
      "string";

    if (scrollReachedEnd && lastCellIsValidEmployee) {
      setPaginationState({
        ...paginationState,
        index: paginationState.index,
        pageSize: paginationState.pageSize + PAGE_SIZE_DEFAULT_STEP,
      });

      getEmployeesToAdd.mutate({
        companyId: currentCompanyId,
        query: "",
        page: paginationState?.pageNumber || 1,
        limit: paginationState?.pageSize + PAGE_SIZE_DEFAULT_STEP,
      });
    }
  };

  useEffect(() => {
    getEmployeesToAdd.mutate({
      companyId: currentCompanyId,
      query: "",
      page: paginationState?.pageNumber || 1,
      limit: paginationState?.pageSize || PAGE_SIZE_DEFAULT_STEP,
    });
  }, []);

  return (
    <Container>
      <HeaderContainer>
        <GoBackIcon onClick={() => navigate(routes?.pageMain)}>
          <Icons name={"IconChevronLeft"} color="#6B5B66" fill="white" />
        </GoBackIcon>
        <LinkButton
          variant={"primary"}
          style={{ alignSelf: "center", color: "#83727D" }}
          onClick={() => navigate(routes?.pageMain)}
        >
          Organograma
        </LinkButton>
        <Icons name={"IconChevronRight"} color="#83727D" fill="white" />
        <AddMutlipleText variant={"body3"}>
          Adicionar várias pessoas
        </AddMutlipleText>
      </HeaderContainer>

      <MainContainer>
        <DescriptionContainer>
          <TitleDescription variant="headline6">
            Adicionar várias pessoas
          </TitleDescription>
          <SubTitleDescription variant="headline7">
            Selecione pela lista
          </SubTitleDescription>
          <DescriptionText variant="body3">
            Escolha quais pessoas você gostaria de adicionar à posição que você
            escolheu no organograma
          </DescriptionText>
        </DescriptionContainer>

        <ListTransferContainer>
          <TextList variant="body3">Liderança direta</TextList>
          <SelectFieldStyled
            label={"Pessoa selecionada"}
            value={parentId}
            disabled={true}
            options={availableEmployees}
          />

          <TransferListStyled
            columns={columns}
            leftList={{
              data: filteredLeftList,
              total: leftListData?.length,
              loading: getEmployeesToAdd?.isLoading,
              title: `Pessoas que não estão alocadas (${leftListData?.length})`,
              onSearch: handleLeftListSearch,
              onScroll: (_, hasReachedEnd) => {
                onScrollReachEnd(hasReachedEnd);
              },
              onCheck: ({ allChecked, data }) => {
                const base = _.cloneDeep(leftListData);

                setLeftListData(
                  base?.map((row) => {
                    const updated = data?.find((d) => d._id === row._id);
                    return {
                      ...row,
                      checked:
                        allChecked !== undefined
                          ? allChecked
                          : updated
                          ? updated.checked
                          : row.checked,
                    };
                  })
                );
              },
            }}
            rightList={{
              data: rightListData,
              total: rightListData?.length,
              loading: false,
              title: `Selecionados para o organograma (${rightListData?.length})`,
              onSearch: (result) => {
                const filter = rightListData?.map((employee) => {
                  if (employee?.label?.match(RegExp(result, "ig")))
                    return { ...employee, hidden: false };
                  return { ...employee, hidden: true };
                });
                setRightListData([...filter]);
              },
              onCheck: ({ allChecked, data }) => {
                const base = _.cloneDeep(rightListData);

                setRightListData(
                  base?.map((row) => {
                    const updated = data?.find((d) => d._id === row._id);
                    return {
                      ...row,
                      checked:
                        allChecked !== undefined
                          ? allChecked
                          : updated
                          ? updated.checked
                          : row.checked,
                    };
                  })
                );
              },
            }}
            onTransfer={({
              leftList: transferLeft,
              rightList: transferRight,
            }) => {
              const leftBase = _.cloneDeep(filteredLeftList);
              const rightBase = _.cloneDeep(rightListData);

              const hasAdded = leftBase
                .filter((l) => l.checked)
                .some((l) =>
                  (transferRight?.data || []).find((tr) => tr._id === l._id)
                );

              const hasRemoved = rightBase
                .filter((l) => l.checked)
                .some((l) =>
                  (transferLeft?.data || []).find((tl) => tl._id === l._id)
                );

              if (hasAdded) {
                const added = leftBase
                  ?.filter((lb) => lb.checked)
                  ?.map((lb) => ({ ...lb, checked: false }));

                rightBase.push(...added);

                const removed = leftBase
                  ?.filter((lb) => !lb.checked)
                  ?.map((lb) => ({ ...lb, checked: false }));

                setRightListData(rightBase);
                setLeftListData(removed);

                return;
              }

              if (hasRemoved) {
                const added = rightBase
                  ?.filter((rb) => rb.checked)
                  ?.map((rb) => ({ ...rb, checked: false }));

                leftBase.push(...added);

                const removed = rightBase
                  ?.filter((rb) => !rb.checked)
                  ?.map((rb) => ({ ...rb, checked: false }));

                setRightListData(removed);
                setLeftListData(leftBase);
              }
            }}
          />
        </ListTransferContainer>
      </MainContainer>

      <FooterContainer>
        <LinkButton
          variant={"primary"}
          style={{ alignSelf: "center", color: "#83727D" }}
          onClick={() => navigate(-1)}
        >
          Cancelar
        </LinkButton>

        <ButtonsContainer>
          <Button
            disabled={addMultiplePersonToOrgchart.isLoading}
            loading={addMultiplePersonToOrgchart.isLoading}
            variant="primary"
            size="large"
            style={{ color: "white" }}
            onClick={() => {
              saveMultiplePeople();
              segment({
                track: `people_strategic_hr_orgchart_addmorepeople_option_clicked`,
              });
            }}
          >
            {addMultiplePersonToOrgchart.isLoading ? (
              <Loader variant="primary" size="small" />
            ) : (
              "Adicionar pessoas"
            )}
            <Icons
              name={"IconArrowRight"}
              style={{ alignSelf: "center", marginLeft: "10px" }}
            />
          </Button>
        </ButtonsContainer>
      </FooterContainer>
    </Container>
  );
};
