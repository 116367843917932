import styled from "styled-components";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 40%;
  left: 50%;
`;

export const Text = styled(Typography)`
  color: #83727d;
`;
