import { LinkButton, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { segment } from "../../../../../../../../utils/segment";
import { ModalFooterDiv, StyledButton } from "./styled";

export const ModalFooter = ({
  handleExport,
  closeModal,
  setSelectedLeader,
  setSelectedLevel,
  setLevelValue,
  isLoading,
  selectedFormat,
  setSelectedOption,
}) => {
  return (
    <ModalFooterDiv>
      <div>
        <LinkButton
          variant="primary"
          onClick={() => {
            closeModal();
            setSelectedLeader(null);
            setSelectedLevel(null);
            setLevelValue({ value: 0, label: "Todos os níveis" });
            setSelectedOption("Organograma completo");
            segment({
              track: `people_strategic_hr_orgchart_exportorgchart_button_exportorgchart_modal_cancel_option_clicked`,
            });
          }}
        >
          Cancelar
        </LinkButton>
      </div>
      <StyledButton
        variant="primary"
        loading={isLoading}
        disabled={isLoading}
        size="large"
        onClick={() => {
          handleExport(selectedFormat);
          segment({
            track: `people_strategic_hr_orgchart_exportorgchart_button_exportorgchart_modal_export_button_clicked`,
          });
        }}
      >
        <Typography variant="body3" weight={700}>
          Exportar
        </Typography>
      </StyledButton>
    </ModalFooterDiv>
  );
};
