import { avatarName } from "../../../../../utils/avatarName";
import { AvatarImage, AvatarName, Container } from "./styled";

interface IAvatar {
  name: string;
  src?: string;
}

export const Avatar = ({ name, src }: IAvatar) => {
  return (
    <Container hasImage={!!src}>
      {src ? (
        <AvatarImage src={src} />
      ) : (
        <AvatarName variant="headline3" weight={700}>
          {avatarName(name)}
        </AvatarName>
      )}
    </Container>
  );
};
