import {
  Divider,
  LinkButton,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import {
  AlertContainer,
  AlertIcon,
  AttentionText,
  CloseIcons,
  Content,
  FooterContainer,
  ModalStyled,
  ModalTextExplanation,
  StyledButton,
  TextContainer,
} from "./styled";
import { segment } from "src/utils/segment";
import { trpc } from "@api/client";
import dispatchToast from "src/utils/dispatchToast";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";

export const ModalExclude = ({
  modalRemove,
  setModalRemove,
  refetch,
  orgchartRef,
}) => {
  const { selectedCompany } = useSelectedCompany();

  const currentCompanyId = selectedCompany?.id;

  const handleClose = () => {
    setModalRemove({ open: false, id: "" });
  };

  const removePersonFromOrgChart = trpc.removePersonFromOrgChart.useMutation({
    onSuccess: async () => {
      refetch();
      handleClose();
      dispatchToast({
        type: "success",
        content: "Pessoa removida do organograma com sucesso",
      });
    },
    onError: () => {
      dispatchToast({
        type: "warning",
        content: "Não foi possível remover essa pessoa do organograma",
      });
    },
  });

  const removeFromOrgChart = async () => {
    try {
      removePersonFromOrgChart.mutate({
        employeeId: modalRemove.id,
        companyId: currentCompanyId,
      });

      orgchartRef?.current?.expandAll()?.fit();

      handleClose();
    } catch (err) {}
  };

  return (
    <ModalStyled open={modalRemove.open} onClose={handleClose}>
      <div>
        <Content>
          <CloseIcons onClick={() => handleClose()}>x</CloseIcons>

          <TextContainer>
            <AlertContainer>
              <AlertIcon name="IconAlertCircle" size={40} fill="transparent" />
            </AlertContainer>

            <AttentionText variant="body3">Atenção</AttentionText>

            <Typography
              variant="headline6"
              style={{
                textAlign: "center",
                marginTop: "16px",
                marginBottom: "16px",
              }}
            >
              Tem certeza que deseja excluir essa pessoa?
            </Typography>

            <ModalTextExplanation
              variant="body3"
              style={{ textAlign: "center" }}
            >
              Você irá apagar esta pessoa e seu liderados apenas no organograma
              e isso não afetará qualquer informação dela na plataforma
            </ModalTextExplanation>
          </TextContainer>
        </Content>

        <Divider orientation="horizontal" />

        <FooterContainer>
          <LinkButton
            variant={"secondary"}
            style={{ alignSelf: "center" }}
            onClick={() => setModalRemove({ open: false, id: "" })}
          >
            Cancelar
          </LinkButton>

          <StyledButton
            loading={removePersonFromOrgChart.isLoading}
            disabled={removePersonFromOrgChart.isLoading}
            variant="primary"
            size="large"
            onClick={() => {
              removeFromOrgChart();
              segment({
                track: `people_strategic_hr_orgchart_delete_option_clicked`,
              });
            }}
          >
            <AttentionText variant="body3">Confirmar exclusão</AttentionText>
            <AlertIcon name="IconTrash" size={20} fill="transparent" />
          </StyledButton>
        </FooterContainer>
      </div>
    </ModalStyled>
  );
};
