export const formatRegistrationNumber = (registratioNumber: string) => {
  registratioNumber = registratioNumber.replace(/\D/g, "");

  registratioNumber = registratioNumber.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
    "$1.$2.$3/$4-$5"
  );

  return registratioNumber;
};
