import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2";
import {
  BottomContainer,
  CenteredSkeletonDiv,
  Container,
  GapDiv,
  MiddleDiv,
  SpaceBetweenDiv,
  TopContainer,
} from "./styled";

export const PersonDetailsSkeleton = () => {
  return (
    <Container>
      <TopContainer>
        <Skeleton variant="circular" width={200} height={200} />
        <CenteredSkeletonDiv>
          <Skeleton
            variant="rectangular"
            width={200}
            height={21}
            sx={{ borderRadius: "25px" }}
          />
          <Skeleton
            variant="rectangular"
            width={100}
            height={17.5}
            sx={{ borderRadius: "25px" }}
          />
        </CenteredSkeletonDiv>
        <Skeleton
          variant="rectangular"
          width={200}
          height={14}
          sx={{ borderRadius: "25px" }}
        />
      </TopContainer>
      <MiddleDiv>
        <Skeleton
          variant="rectangular"
          width={200}
          height={17.5}
          sx={{ borderRadius: "25px" }}
        />
        <Skeleton variant="circular" width={10.5} height={10.5} />
      </MiddleDiv>
      <BottomContainer>
        <GapDiv>
          <Skeleton
            variant="rectangular"
            width={200}
            height={10}
            sx={{ borderRadius: "25px" }}
          />
          <SpaceBetweenDiv>
            <Skeleton
              variant="rectangular"
              width={180}
              height={14}
              sx={{ borderRadius: "25px" }}
            />
            <Skeleton variant="circular" width={10.5} height={10.5} />
          </SpaceBetweenDiv>
        </GapDiv>
        <GapDiv>
          <Skeleton
            variant="rectangular"
            width={200}
            height={10}
            sx={{ borderRadius: "25px" }}
          />
          <Skeleton
            variant="rectangular"
            width={200}
            height={14}
            sx={{ borderRadius: "25px" }}
          />
        </GapDiv>
        <GapDiv>
          <Skeleton
            variant="rectangular"
            width={200}
            height={10.5}
            sx={{ borderRadius: "25px" }}
          />
          <Skeleton
            variant="rectangular"
            width={200}
            height={14}
            sx={{ borderRadius: "25px" }}
          />
        </GapDiv>
        <GapDiv>
          <Skeleton
            variant="rectangular"
            width={200}
            height={10.5}
            sx={{ borderRadius: "25px" }}
          />
          <Skeleton
            variant="rectangular"
            width={200}
            height={14}
            sx={{ borderRadius: "25px" }}
          />
        </GapDiv>
      </BottomContainer>
    </Container>
  );
};
