import {
  Button,
  Icons,
  Modal,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const ModalStyled = styled(Modal)`
  && {
    .modal-content-area {
      padding: 0px;
    }
  }

  .MuiPopover-root {
    width: 871px;
    height: 460px;
    padding: 44px 0px 0px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 44px 72px;
  position: relative;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 44px 72px;
`;

export const CloseIcons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  border: 1px solid #d1c7ce;
  border-radius: 40px;
  margin-left: auto;
  cursor: pointer;
`;

export const MessageContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.feedback.info[70]};
  border-radius: 8px;
  width: 100%;
  max-width: 647px;
  height: 96px;
  padding: 24px;
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  align-items: center;
`;

export const IconWarningContainer = styled.div`
  width: 48px;
  min-width: 48px;
  max-width: 48px;
  height: 48px;
  min-height: 48px;
  max-height: 48px;
  padding: 8px;
  background: ${({ theme }) => theme.colors.feedback.info[90]};
  border-radius: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconWarning = styled(Icons)`
  color: ${({ theme }) => theme.colors.feedback.info[50]};
`;

export const TextContainerWarning = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
`;

export const WarningMessage = styled(Typography)`
  color: ${({ theme }) => theme.colors.feedback.info[10]};
`;

export const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 647px;
  height: 96px;
  margin-top: 16px;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.neutral[80]};
  border-radius: 8px;
  padding: 24px;
`;

export const ToggleMessage30 = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const ToggleMessage50 = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 22px 44px;
`;

export const ButtonSave = styled(Button)`
  && {
    &.MuiButtonBase-root.size-large {
      padding: 20px 75px;
    }
  }
`;
