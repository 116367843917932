import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  padding: 40px 24px 0px;
`;

const Company = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  margin-top: 6px;

  color: ${({ theme }) => theme.colors.neutral[40]};
`;

interface ITitle {
  count: number | undefined;
}

export const Title = ({ count }: ITitle) => {
  const { selectedCompany } = useSelectedCompany();
  const name = selectedCompany?.name;

  return (
    <Container>
      <Typography variant="headline6">Organograma</Typography>
      <Company>
        <Icons name="IconBuilding" fill="transparent" size={14} />
        <Typography variant="body4">{name}</Typography>
        <Typography variant="body4">•</Typography>
        <Icons name="IconUsers" fill="transparent" size={14} />
        <Typography variant="body4">
          {count} {count === 1 ? "pessoa" : "pessoas"}
        </Typography>
      </Company>
    </Container>
  );
};
