import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

export const TitleBox = ({
  title,
  subtitle,
}: {
  title: string;
  subtitle: string;
}) => {
  return (
    <div style={{ width: "100%", padding: "32px 32px 0" }}>
      <Typography variant="headline8" weight={700}>
        {title}
      </Typography>
      <Typography variant="body4" weight={400} style={{ color: "#83727D" }}>
        {subtitle}
      </Typography>
    </div>
  );
};
